<template>
  <app-timeline>
    <app-timeline-item
      v-for="item in ticketsLog"
      :key="item.ticketBitacoraId"
      :variant="resolveVariant(item.evento)"
    >
      <div>
        {{ item.evento }}
      </div>
      <div>
        {{ item.observaciones }}
      </div>
      <div>
        {{ item.fechaHora }}
      </div>
      <div>
        {{ item.usuario }}
      </div>
    </app-timeline-item>
  </app-timeline>
</template>
<script>
import { ref } from '@vue/composition-api'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ticketService from '@/services/helpDesk.service'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    ticketId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { fetchTicketLog } = ticketService()
    const ticketsLog = ref([])
    fetchTicketLog(props.ticketId, data => {
      ticketsLog.value = data
    })

    const getTicketLog = () => {
      fetchTicketLog(props.ticketId, data => {
        ticketsLog.value = data
      })
    }

    const resolveVariant = event => {
      if (event === 'ALTA TICKET') return 'primary'
      if (event === 'CAMBIO SOLUCION') return 'info'
      if (event === 'CAMBIO DESCRIPCION') return 'warning'
      if (event === 'CAMBIO NOMBRE') return 'secondary'
      if (event === 'CAMBIO IP') return 'info'
      if (event === 'CAMBIO USUARIO') return 'secondary'
      if (event === 'CAMBIO REPARADOR') return 'warning'
      if (event === 'CAMBIO CATEGORIA') return 'info'
      if (event === 'CAMBIO PRIORIDAD') return 'warning'
      if (event === 'CAMBIO ESTATUS') return 'info'
      if (event === 'TICKET CERRADO') return 'success'
      if (event === 'TICKET CANCELADO') return 'danger'
      return ''
    }

    return {
      ticketsLog,
      resolveVariant,
      getTicketLog,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
