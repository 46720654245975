<template>
  <b-card
    no-body
    class="p-1"
  >
    <TicketEditTabLog :ticket-id="$router.currentRoute.params.ticketId" />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import TicketEditTabLog from '../tickets-edit/TicketEditTabLog.vue'

export default {
  components: {
    TicketEditTabLog,
    BCard,
  },
}
</script>
